import React, {useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import ReservationForm from "./ReservationForm";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const ReservationDialog = props => {
  const classes = useStyles();
  const {initialValues, handleClose, isOpen, approved} = props;

  return (
    <Dialog
      classes={{ paper: classes.paper}}
      maxWidth={"sm"}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Reservation Form
          </Typography>
        </Toolbar>
      </AppBar>
      <ReservationForm initialValues={initialValues || {}} approved={approved}/>
    </Dialog>
  );
};

export default (ReservationDialog);