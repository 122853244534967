import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import makeStyles from "@material-ui/core/styles/makeStyles";
import UserTableRow from "./UserTableRow";
import {getAllUsers} from "../../actions/users.service";

const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: 1200,
    margin: theme.spacing(4),
  }
}));

const UserTable = props => {
  const classes = useStyles();
  const {users, getAllUsers} = props;

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  return (
    <Table className={classes.table} aria-label="user table">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="center">Username</TableCell>
          <TableCell align="center">E-Mail</TableCell>
          <TableCell align="center">Permission Level</TableCell>
          <TableCell align="center">Edit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user) => <UserTableRow user={user}/>)}
      </TableBody>
    </Table>
  );
};

export default connect(state => ({ users: state.users }), { getAllUsers })(UserTable);