import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from "redux-form";
import {renderTextField} from "../form-wrappers/form-fields";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import logo from '../../images/wh.svg';
import CardMedia from "@material-ui/core/CardMedia";
import FormPage from "../form-wrappers/FormPage";
import Typography from "@material-ui/core/Typography";
import {startResetPasswordProcess} from "../../actions/users.service";
import {Redirect} from "react-router-dom";
import {clearEvent} from "../../actions/apievents";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "350px",
    padding: theme.spacing(2)
  },
  form: {
    display: "flex",
    flexDirection: "column"
  },
  img: {
    objectFit: "contain"
  },
  message: {
    marginTop: theme.spacing(2),
    fontSize: 16
  }
}));

const RecoveryForm = props => {
  const classes = useStyles();
  const { shouldRedirect, handleSubmit, pristine, submitting } = props;

  const onSubmit = values => props.startResetPasswordProcess(values.email);

  if(shouldRedirect) {
    props.clearEvent("POST_START_RESET_PASSWORD_EVENT");

    return <Redirect to={{
      pathname: '/',
      state: { from: props.location }
    }} />
  }

  return (
    <FormPage>
      <Card className={classes.card} raised={true}>
        <CardMedia component="img" height="140" image={logo} classes={{img: classes.img}}/>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Typography
            color="textSecondary"
            className={classes.message}
          >
            If you have forgotten your password please enter the E-Mail associated with your account.
            You should receive an E-Mail shortly after with instructions on how to reset your password.
          </Typography>
          <Field
            name="email"
            component={renderTextField}
            label="E-Mail Address"
            type="email"
            required
          />
          <Button variant="contained" color="primary" type="submit" disabled={pristine || submitting} margin="normal">
            Recover Account
          </Button>
        </form>
      </Card>
    </FormPage>
  );
};

export default connect((state) => ({
  shouldRedirect: state.apiEvents.POST_START_RESET_PASSWORD_EVENT && state.apiEvents.POST_START_RESET_PASSWORD_EVENT.success
}), {startResetPasswordProcess, clearEvent})(reduxForm({
  form: 'RecoveryForm'
})(RecoveryForm));