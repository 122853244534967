import React from 'react';
import {connect} from 'react-redux';
import {reduxForm} from "redux-form";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import ReservationFormFields from "./ReservationFormFields";
import {
  createReservation,
  declineReservation,
  deleteReservation,
  updateReservation
} from "../../actions/calendar.service";
import {showAlert} from "../../actions/notification";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4)
  },
  longButton: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2)
  },
  twoColumns: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "48% 48%",
    columnGap: theme.spacing(2)
  },
}));

const ReservationForm = props => {
  const classes = useStyles();
  const { handleSubmit, submitting, approved } = props;

  const onSubmit = values => {
    const {action, ...rest} = values;

    let reservation = {
      rooms: []
    }

    console.log(rest);

    for (let [key, value] of Object.entries(rest)) {
      if(typeof value === "boolean" && value)
        reservation.rooms.push(key);
      else
        reservation[key] = value;
    }

    if(reservation.rooms.length === 0) {
      props.showAlert("warning", "You must select at lease 1 room to reserve!");
      return;
    }

    let start = new Date(reservation.start);
    start.setHours(13, 0, 0, 0);

    let end = new Date(reservation.end);
    end.setHours(11, 0, 0, 0);

    reservation.start = start;
    reservation.end = end;

    switch(action) {
      case "edit": return props.updateReservation(reservation);
      case "delete": return props.deleteReservation(reservation);
      case "approve":return props.createReservation(reservation, true);
      case "decline": return props.deleteReservation(reservation);
      case "deletePending": return props.deleteReservation(reservation, true);
      case "reservation": return props.createReservation(reservation, false);
      default:
        break;
    }
  }

  const createSubmitButton = (action, actionText) => {
    return (
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={submitting}
        margin="normal"
        onClick={handleSubmit(values => onSubmit({...values, action}))}>
        {actionText}
      </Button>
    );
  }

  const getButtonsForForm = () => {
    switch(approved) {
      case true:
        return (
          <div className={classes.twoColumns}>
            {createSubmitButton("edit", "Edit and Save")}
            {createSubmitButton("delete", "Delete Reservation")}
          </div>
        );
      case false:
        return (
          <>
            <div className={classes.twoColumns}>
              {createSubmitButton("approve", "Approve Reservation")}
              {createSubmitButton("decline", "Request More Info")}
            </div>
            <div className={classes.longButton}>
              {createSubmitButton("deletePending", "Delete Pending Reservation")}
            </div>
          </>
        );
      default:
        return createSubmitButton("reservation", "Apply For Reservation");
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <form className={classes.form}>
        <ReservationFormFields/>
        {getButtonsForForm()}
      </form>
    </MuiPickersUtilsProvider>
  );
};

export default connect(
  null,
  {createReservation, deleteReservation, updateReservation, declineReservation, showAlert})(
  reduxForm({
    form: 'ReservationForm'
  })(ReservationForm)
);