import client from './wheelerheirs.axios';
import jwtDecode from 'jwt-decode';
import {getUserProfile} from "./users.service";
import {showErrorAlert} from "./notification";

export const login = (username, password) => async (dispatch, getState) => {
  await client().post(`/auth`, {username, password})
    .then((user) => user.data)
    .then((user) => {
      dispatch({
        type: "FETCH_USER_INFO",
        payload: {
          accessToken: user.accessToken,
          ...jwtDecode(user.accessToken)
        },
      });

      getUserProfile(username)(dispatch, getState);

      dispatch({
        type: "FETCH_USER_INFO_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      if(error.response.status === 403) {
        showErrorAlert(error, "Account awaiting approval, please wait for an admin to approve your account before signing in.")(dispatch, getState);
      } else {
        showErrorAlert(error, "Username or Password was Incorrect")(dispatch, getState);
      }

      dispatch({
        type: "FETCH_USER_INFO_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};

export const logout = () => async dispatch => {
  dispatch({
    type: "FETCH_USER_INFO",
    payload: {
      logout: true
    }
  });
};
