import React, {useState} from 'react';
import {connect} from 'react-redux';
import makeStyles from "@material-ui/core/styles/makeStyles";
import ReservationDialog from "./ReservationDialog";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Fab from "@material-ui/core/Fab";
import {clearEvent} from "../../actions/apievents";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4)
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ReservationFab = props => {
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }

  if(props.closeModal && isOpen) {
    props.clearEvent("POST_RESERVATION_EVENT");
    setOpen(false);
  }

  return (
    <>
      <ReservationDialog handleClose={handleClose} isOpen={isOpen}/>
      <Fab variant="extended" color="primary" aria-label="add" onClick={handleOpen} className={classes.fab}>
        <AddCircleIcon className={classes.extendedIcon} />
        Make A Reservation
      </Fab>
    </>
  );
};

export default connect((state) => ({
  closeModal: state.apiEvents.POST_RESERVATION_EVENT && state.apiEvents.POST_RESERVATION_EVENT.success
}), {clearEvent})(ReservationFab);