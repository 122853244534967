export const start = new Date(new Date().getFullYear(), 0, 1);
export const end =  new Date(new Date().getFullYear(), 11, 31);

export const months = () => ({
  id: "months",
  title: "Months",
  style: {},
  useAsGrid: true,
  cells: [
    {id: "m0", title: "Jan", start: new Date(new Date().getFullYear(), 0, 1), end: new Date(new Date().getFullYear(), 1, 1)},
    {id: "m1", title: "Feb", start: new Date(new Date().getFullYear(), 1, 1), end: new Date(new Date().getFullYear(), 2, 1)},
    {id: "m2", title: "Mar", start: new Date(new Date().getFullYear(), 2, 1), end: new Date(new Date().getFullYear(), 3, 1)},
    {id: "m3", title: "Apr", start: new Date(new Date().getFullYear(), 3, 1), end: new Date(new Date().getFullYear(), 4, 1)},
    {id: "m4", title: "May", start: new Date(new Date().getFullYear(), 4, 1), end: new Date(new Date().getFullYear(), 5, 1)},
    {id: "m5", title: "Jun", start: new Date(new Date().getFullYear(), 5, 1), end: new Date(new Date().getFullYear(), 6, 1)},
    {id: "m6", title: "Jul", start: new Date(new Date().getFullYear(), 6, 1), end: new Date(new Date().getFullYear(), 7, 1)},
    {id: "m7", title: "Aug", start: new Date(new Date().getFullYear(), 7, 1), end: new Date(new Date().getFullYear(), 8, 1)},
    {id: "m8", title: "Sep", start: new Date(new Date().getFullYear(), 8, 1), end: new Date(new Date().getFullYear(), 9, 1)},
    {id: "m9", title: "Oct", start: new Date(new Date().getFullYear(), 9, 1), end: new Date(new Date().getFullYear(), 10, 1)},
    {id: "m10", title: "Nov", start: new Date(new Date().getFullYear(), 10, 1), end: new Date(new Date().getFullYear(), 11, 1)},
    {id: "m11", title: "Dec", start: new Date(new Date().getFullYear(), 11, 1), end: new Date(new Date().getFullYear() + 1, 0, 1)}
  ]
});

export const days = () => {
  let days = [];

  for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    days.push({
      id: "d" + d.valueOf(),
      title: `${d.getDate()}`,
      start: new Date(d),
      end: new Date(new Date(d).setDate(d.getDate() + 1))
    })
  }

  return {
    id: "days",
    title: "Days",
    style: {},
    useAsGrid: true,
    cells: days
  };
};