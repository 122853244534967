import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getAllDocuments} from "../../actions/document.service";
import List from "@material-ui/core/List";
import DocumentFolder from "./DocumentFolder";
import DocumentDisplay from "./DocumentDisplay";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: theme.spacing(6)
  }
}));

const DocumentPage = (props) => {
  const classes = useStyles();
  const {getAllDocuments, documents} = props;

  useEffect(() => {
    getAllDocuments();
  }, [getAllDocuments]);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {documents.map((folderName) => {
        return <DocumentFolder
          key={folderName}
          folder={folderName}
          folderName={folderName.toUpperCase()}
        />
      })}
      <DocumentDisplay/>
    </List>
  );
};

export default connect((state) => {
  return {
    documents: Object.keys(state.documents)
  }
}, {getAllDocuments})(DocumentPage);