import React, { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "45px",
  borderWidth: 1,
  borderRadius: 0,
  borderColor: "#aaaaaa",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  fontFamily: "Roboto",
  fontSize: "1.2rem"
};

const activeStyle = {
  backgroundColor: "#2196f3"
};

const acceptStyle = {
  backgroundColor: "#99ffce"
};

const rejectStyle = {
  backgroundColor: "#ffb3c2"
};

export default function FileUploadMini(props) {
  const maxSize = 1024 * 1000 * 1000;

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map(file => {
      props.uploadCallback(file);
    });
  }, []);

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    isDragAccept,
    acceptedFiles,
    rejectedFiles
  } = useDropzone({
    onDrop,
    accept: props.acceptedTypes,
    minSize: 0,
    maxSize
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject]
  );

  const isFileTooLarge = rejectedFiles &&
    rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {!isDragActive && <p>Click here or drop a file to upload!</p>}
      {isDragActive && !isDragReject && <p>Drop it like it's HOT!</p>}
      {isDragReject && <p>File type not accepted, sorry!</p>}
      {isFileTooLarge && (
        <div className="text-danger mt-2">File is too large.</div>
      )}
    </div>
  );
}
