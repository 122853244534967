import React, {useState} from 'react';
import {connect} from 'react-redux';
import DeleteFileAction from "./DeleteFileAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {getDocument} from "../../actions/document.service";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

const DocumentFile = props => {
  const { folder, document, getDocument } = props;

  return (
    <ListItem
      key={document}
      button
      onClick={() => getDocument(folder, document)}
    >
      <ListItemIcon>
        <InsertDriveFileIcon/>
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography noWrap={true}>
            {document}
          </Typography>
        }
      />
      <ListItemSecondaryAction>
        <DeleteFileAction document={document} folder={folder}/>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default connect(null, {getDocument})(DocumentFile);