import React from 'react';
import {connect} from 'react-redux';
import {clearEvent} from "../../actions/apievents";
import Dialog from "@material-ui/core/Dialog";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AppBar from "@material-ui/core/AppBar";
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    height: '100%'
  },
  documentFrame: {
    width: '100%',
    height: '100%',
    border: 'none'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const DocumentDisplay = props => {
  const classes = useStyles();
  const {currentDocument, currentDocumentName, clearEvent} = props;

  const handleClose = () => {
    clearEvent("FETCH_CURRENT_DOCUMENT_EVENT");
  };

  return (
    <Dialog
      classes={{ paper: classes.paper}}
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={currentDocument !== null}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {currentDocumentName}
          </Typography>
        </Toolbar>
      </AppBar>
      <iframe
        src={currentDocument}
        className={classes.documentFrame}
      />
    </Dialog>
  );
};

export default connect((state) => {
  const event = state.apiEvents.FETCH_CURRENT_DOCUMENT_EVENT;

  console.log(state);

  return {
    currentDocument: event && event.success ? event.data : null,
    currentDocumentName: event && event.success ? event.document : null
  };
}, {clearEvent})(DocumentDisplay);