import client from "./wheelerheirs.axios";
import {clearEvent} from "./apievents";
import {showAlert, showErrorAlert} from "./notification";

const eventsToGroups = (events, pending = false) => {
  let groups = {
    "Linger Bedroom": {
      id: "Linger Bedroom",
      title: "Linger Bedroom",
      idOpen: false,
      tracks: [],
      elements: []
    },
    "Longer Bedroom": {
      id: "Longer Bedroom",
      title: "Longer Bedroom",
      idOpen: false,
      tracks: [],
      elements: []
    },
    "Sunset": {
      id: "Sunset",
      title: "Sunset",
      idOpen: false,
      tracks: [],
      elements: []
    },
    "Citadel": {
      id: "Citadel",
      title: "Citadel",
      idOpen: false,
      tracks: [],
      elements: []
    },
    "Sunrise": {
      id: "Sunrise",
      title: "Sunrise",
      idOpen: false,
      tracks: [],
      elements: []
    },
    "WeeHoos": {
      id: "WeeHoos",
      title: "WeeHoos",
      idOpen: false,
      tracks: [],
      elements: []
    },
    "Dining Porch Couch": {
      id: "Dining Porch Couch",
      title: "Dining Porch Couch",
      idOpen: false,
      tracks: [],
      elements: []
    },
    "Attic": {
      id: "Attic",
      title: "Attic",
      idOpen: false,
      tracks: [],
      elements: []
    },

  };

  events.forEach((event) => {
    event.rooms.forEach(room => {
      if(!groups[room]) {
        groups[room] = {
          id: room,
          title: room,
          idOpen: false,
          tracks: [],
          elements: []
        }
      }

      groups[room].elements.push({
        id: event.id,
        start: new Date(event.start),
        end: new Date(event.end),
        title: event.family,
        style: {
          backgroundColor: pending ? "#ff3d00" : "#4caf50",
          borderRadius: "4px",
          boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)",
          color: "#000000",
          textTransform: "capitalize"
        },
        fullEvent: {...event, approved: !pending}
      });
    })
  });

  return Object.keys(groups).map(group => groups[group]);
}

export const selectEvent = (event) => async (dispatch, getState) => {
  dispatch({
    type: "SELECT_CALENDAR_EVENT",
    payload: {
      success: true,
      event
    },
  });
}

export const getAllEvents = () => async (dispatch, getState) => {
  await client(getState().user.accessToken).get(`/calendar/events`)
    .then((events) => {
      console.log(events);
      dispatch({
        type: "FETCH_CALENDAR",
        payload: eventsToGroups(events.data),
      });

      dispatch({
        type: "FETCH_CALENDAR_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      showErrorAlert(error, "We are not sure what happened here?")(dispatch, getState);

      dispatch({
        type: "FETCH_CALENDAR_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};

export const getPendingEvents = () => async (dispatch, getState) => {
  await client(getState().user.accessToken).get(`/calendar/events?pending`)
    .then((events) => {
      dispatch({
        type: "FETCH_CALENDAR_PENDING",
        payload: eventsToGroups(events.data, true),
      });

      dispatch({
        type: "FETCH_CALENDAR_PENDING_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      showErrorAlert(error, "We are not sure what happened here?")(dispatch, getState);

      dispatch({
        type: "FETCH_CALENDAR_PENDING_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};

export const createReservation = (reservation, approved) => async (dispatch, getState) => {
  await client(getState().user.accessToken).post(`/calendar/events${approved ? "?approved" : ""}`, reservation)
    .then(() => {
      getAllEvents()(dispatch, getState);
      getPendingEvents()(dispatch, getState);

      if(approved)
        clearEvent("SELECT_CALENDAR_EVENT")(dispatch, getState);

      dispatch({
        type: "POST_RESERVATION_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      showErrorAlert(error, `Error ${approved ? "approving" : "creating"} reservation, reservation may contain invalid data.`)(dispatch, getState);

      dispatch({
        type: "POST_RESERVATION_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};

export const deleteReservation = (reservation, pending = false) => async (dispatch, getState) => {
  await client(getState().user.accessToken).delete(`/calendar/events/${reservation.id}${pending ? "?pending" : ""}`)
    .then(() => {
      getAllEvents()(dispatch, getState);
      getPendingEvents()(dispatch, getState);

      clearEvent("SELECT_CALENDAR_EVENT")(dispatch, getState);

      dispatch({
        type: "DELETE_APPROVED_RESERVATION_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      showErrorAlert(error, "We are not sure what happened here?")(dispatch, getState);

      dispatch({
        type: "DELETE_APPROVED_RESERVATION_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};

export const updateReservation = (reservation) => async (dispatch, getState) => {
  await client(getState().user.accessToken).put(`/calendar/events/${reservation.id}`, reservation)
    .then(() => {
      getAllEvents()(dispatch, getState);
      getPendingEvents()(dispatch, getState);

      clearEvent("SELECT_CALENDAR_EVENT")(dispatch, getState);

      dispatch({
        type: "UPDATE_APPROVED_RESERVATION_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      showErrorAlert(error, "Could not update reservation, reservation may contain invalid data")(dispatch, getState);

      dispatch({
        type: "UPDATE_APPROVED_RESERVATION_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};

export const declineReservation = (reservation) => async (dispatch, getState) => {
  await client(getState().user.accessToken).post(`/calendar/events/${reservation.id}/decline`, reservation)
    .then(() => {
      clearEvent("SELECT_CALENDAR_EVENT")(dispatch, getState);

      dispatch({
        type: "DECLINE_RESERVATION_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      showErrorAlert(error, "Could not ask for more details, we arnt sure what happened here")(dispatch, getState);

      dispatch({
        type: "DECLINE_RESERVATION_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};
