import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from "redux-form";
import {renderSwitch, renderTextField} from "../form-wrappers/form-fields";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import {deleteUser, patchUser} from "../../actions/users.service";
import {clearEvent} from "../../actions/apievents";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "350px",
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(4)
  }
}));

const UserManagementForm = props => {
  const classes = useStyles();
  const { handleSubmit, pristine, submitting} = props;

  const onSubmit = values => {
    const {isAdmin, enabled, ...user} = values;

    return props.patchUser(
      props.username,
      {
        ...user,
        enabled: enabled,
        permissionLevel: isAdmin ? 2048 : 1
      });
  }

  const deleteUser = () => {
    props.deleteUser(props.initialValues.username);
    props.close();
  }

  return (
    <form autoComplete="off" className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="firstName"
        component={renderTextField}
        label="First Name"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="off"
        required
      />
      <Field
        name="lastName"
        component={renderTextField}
        label="Last Name"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="off"
        required
      />
      <Field
        name="email"
        component={renderTextField}
        label="E-Mail Address"
        type="email"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="off"
        required
      />
      <Field
        name="username"
        component={renderTextField}
        label="Username"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="off"
        required
      />
      <Field
        name="password"
        component={renderTextField}
        label="Password"
        type="password"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="off"
      />
      <Field
        name="enabled"
        component={renderSwitch}
        label="Enabled"
        required
      />
      <Field
        name="isAdmin"
        component={renderSwitch}
        label="Admin"
        required
      />
      <Button variant="contained" color="primary" type="submit" disabled={pristine || submitting} margin="normal">
        Edit User
      </Button>

      <Button variant="contained" color="secondary" margin="normal" onClick={() => deleteUser()} style={{marginTop: '10px'}}>
        Delete User
      </Button>
    </form>
  );
};

export default connect(
  null,
  {patchUser, deleteUser, clearEvent}
)(reduxForm({
  form: 'UserManagementForm'
})(UserManagementForm));