export const showErrorAlert = (error, message) => (dispatch, getState) => {
  if(error.response.status < 500) {
      showAlert("error", message)(dispatch, getState);
  } else {
    showAlert("error", "UhOh, Wheeler Heirs may be having some issues, please try again later")(dispatch, getState);
  }
}

export const showAlert = (severity, message, options) => (
  dispatch,
  getState
) => {
  const position =
    options && options.position
      ? options.position
      : { horizontal: "center", vertical: "bottom" };

  dispatch({
    type: "NOTIFY",
    payload: getState().notifications.concat({
      open: true,
      severity,
      message,
      position,
    }),
  });
};

export const hideAlert = (id) => (dispatch, getState) => {
  const queue = getState().notifications.map((item, index) =>
    index === id ? { ...item, open: false } : item
  );

  dispatch({
    type: "NOTIFY",
    payload: queue,
  });
};

export const deleteAlert = (id) => (dispatch, getState) => {
  const queue = getState().notifications.filter((item, index) => index !== id);

  dispatch({
    type: "NOTIFY",
    payload: queue,
  });
};
