import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import {useHistory} from "react-router-dom";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import {logout} from "../../actions/auth.service";
import UserProfileEditDialog from "../user-profile/UserProfileEditDialog";

const useStyles = makeStyles(theme => ({
  title: {
    marginRight: theme.spacing(4),
    cursor: "pointer"
  },
  spacer: {
    flexGrow: 1,
  },
}));

const RedirectButton = (history, link, name) => {
  return (
    <><Button color="inherit" onClick={() => history.push(link)}>{name}</Button><span>&#8226;</span></>
  );
};

const NavBar = (props) => {
  const classes = useStyles();
  const {isAuthed, isAdmin} = props;
  const history = useHistory();

  if(!isAuthed)
    return null;

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title} onClick={() => history.push("/home")}>
            WheelerHeirs
          </Typography>
          {RedirectButton(history, "/documents", "Documents")}
          {RedirectButton(history, "/calendar", "Calendar")}
          {isAdmin && RedirectButton(history, "/user-management", "User Management")}
          <Button color="inherit" onClick={() => props.logout()}>Logout</Button>
          <div className={classes.spacer}></div>
          <UserProfileEditDialog/>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default connect((state) => (
  {
    isAuthed: state.user.accessToken ? true : false,
    isAdmin: state.user && state.user.permissionLevel >= 2048
  }),
{logout})(NavBar);