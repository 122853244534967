import React, {useState} from 'react';
import {connect} from 'react-redux';
import DeleteIcon from "@material-ui/icons/Delete";
import {deleteDocument} from "../../actions/document.service";
import IconButton from "@material-ui/core/IconButton";
import ParanoidConfirm from "./ParanoidConfirm";

const DeleteFileAction = props => {
  const [isOpen, setOpen] = useState(false);
  const {isAdmin, folder, document, deleteDocument} = props;

  if(!isAdmin)
    return null;

  return (
    <>
      <IconButton
        edge="end"
        aria-label="delete"
        onClick={() => setOpen(true)}
      >
        <DeleteIcon/>
      </IconButton>
      <ParanoidConfirm
        open={isOpen}
        handleClose={() => setOpen(false)}
        handleSubmit={() => {
          deleteDocument(folder, document);
          setOpen(false);
        }}
        resource={"document"}
        confirm={document}
      />
    </>
  );
};

export default connect((state) => ({
  isAdmin: state.user.permissionLevel >= 2048
}), {deleteDocument})(DeleteFileAction);