import React from 'react';
import UserTable from "./UserTable";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

const UserManagementPage = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <UserTable/>
    </div>
  );
};

export default UserManagementPage;