import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from "redux-form";
import {renderTextField} from "../form-wrappers/form-fields";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import logo from '../../images/wh.svg';
import CardMedia from "@material-ui/core/CardMedia";
import FormPage from "../form-wrappers/FormPage";
import {createUser} from "../../actions/users.service";
import {clearEvent} from "../../actions/apievents";
import {Redirect} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "350px",
    padding: theme.spacing(2)
  },
  form: {
    display: "flex",
    flexDirection: "column"
  },
  img: {
    objectFit: "contain"
  }
}));

const validate = values => {
  const errors = {}

  if (values.password !== values.confirmPassword) {
    errors.password = 'Passwords must match!'
    errors.confirmPassword = 'Passwords must match!'
  }

  return errors
}

const RegistrationForm = props => {
  const classes = useStyles();
  const { shouldRedirect, handleSubmit, pristine, submitting} = props;

  const onSubmit = values => {
    const {confirmPassword, ...user} = values;

    return props.createUser(user);
  }

  if(shouldRedirect) {
    props.clearEvent("CREATE_NEW_USER_EVENT");
    return <Redirect to={{
      pathname: '/',
      state: { from: props.location }
    }} />
  }

  return (
    <FormPage>
      <Card className={classes.card} raised={true}>
        <CardMedia component="img" height="140" image={logo} classes={{img: classes.img}}/>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Field
            name="firstName"
            component={renderTextField}
            label="First Name"
            required
          />
          <Field
            name="lastName"
            component={renderTextField}
            label="Last Name"
            required
          />
          <Field
            name="email"
            component={renderTextField}
            label="E-Mail Address"
            type="email"
            required
          />
          <Field
            name="username"
            component={renderTextField}
            label="Username"
            required
          />
          <Field
            name="password"
            component={renderTextField}
            label="Password"
            type="password"
            required
          />
          <Field
            name="confirmPassword"
            component={renderTextField}
            label="Confirm Password"
            type="password"
            required
          />
          <Button variant="contained" color="primary" type="submit" disabled={pristine || submitting} margin="normal">
            Register
          </Button>
        </form>
      </Card>
    </FormPage>
  );
};

export default connect(
  (state) => ({
    shouldRedirect: state.apiEvents.CREATE_NEW_USER_EVENT && state.apiEvents.CREATE_NEW_USER_EVENT.success
  }),
  {createUser, clearEvent}
)(reduxForm({
  form: 'RegistrationForm',
  validate
})(RegistrationForm));