import client from './wheelerheirs.axios';
import {showErrorAlert} from "./notification";

export const getAllDocuments = () => async (dispatch, getState) => {
  await client(getState().user.accessToken).get('/documents')
    .then((documentList) => {
      dispatch({
        type: "FETCH_DOCUMENTS",
        payload: documentList.data
      });

      dispatch({
        type: "FETCH_DOCUMENTS_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      showErrorAlert(error, "Could not get documents, we are not sure what happened")(dispatch, getState);

      dispatch({
        type: "FETCH_DOCUMENTS_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};

export const getDocument = (folder, document) => async (dispatch, getState) => {
  await client(getState().user.accessToken).get(`/documents/${folder}/${document}`, {responseType: 'blob'})
    .then((res) => res.data)
    .then((blob) => (window.URL || window.webkitURL).createObjectURL(blob))
    .then((data) => {
      dispatch({
        type: "FETCH_CURRENT_DOCUMENT_EVENT",
        payload: {
          success: true,
          data,
          document
        },
      });
    })
    .catch((error) => {
      showErrorAlert(error, "Could not get a document, we are not sure what happened")(dispatch, getState);

      dispatch({
        type: "FETCH_CURRENT_DOCUMENT_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};

export const uploadDocument = (folder, document) => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append("document", document);

  await client(getState().user.accessToken).post(`/documents/${folder}`, formData)
    .then(() => {
      getAllDocuments()(dispatch, getState);

      dispatch({
        type: "UPLOAD_DOCUMENT_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      showErrorAlert(error, "Could not upload a document, we are not sure what happened")(dispatch, getState);

      dispatch({
        type: "UPLOAD_DOCUMENT_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
}

export const deleteDocument = (folder, document) => async (dispatch, getState) => {
  await client(getState().user.accessToken).delete(`/documents/${folder}/${document}`)
    .then(() => {
      getAllDocuments()(dispatch, getState);

      dispatch({
        type: "DELETE_DOCUMENT_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      showErrorAlert(error, "Could not delete a document, we are not sure what happened")(dispatch, getState);

      dispatch({
        type: "DELETE_DOCUMENT_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
}
