import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import Timeline from 'react-timelines';
import {getAllEvents, getPendingEvents, selectEvent} from "../../actions/calendar.service";
import {days, end, months, start} from "./calendar";

import 'react-timelines/lib/css/style.css';
import ReservationFab from "../reservation/ReservationFab";
import ReservationEditDialog from "../reservation/ReservationEditDialog";

const CalendarPage = props => {
  const {isAdmin, groups, getAllEvents, getPendingEvents, selectEvent} = props;

  console.log(groups);

  useEffect(() => {
    getAllEvents();
    getPendingEvents();
  }, [getAllEvents, getPendingEvents]);

  const clickEvent = element => {
    if(!isAdmin)
      return;

    selectEvent(element.fullEvent);
  }

  return (
    <>
      <Timeline
        scale={{
          start,
          end,
          zoom: 40,
        }}
        isOpen={false}
        timebar={[months(), days()]}
        tracks={groups}
        now={new Date()}
        enableSticky
        scrollToNow
        clickElement={clickEvent}
      />
      <ReservationFab/>
      <ReservationEditDialog/>
    </>
  );
};

export default connect((state) => {
    let groups = [...state.calendar];

    if(state.user.permissionLevel >= 2048) {
        state.pendingCalendar.forEach(pendingGroup => {
            const matchingGroup = groups.find(group => group.id === pendingGroup.id);

            if(!matchingGroup) {
                groups.push(pendingGroup);
            } else {
                matchingGroup.elements = [...matchingGroup.elements, ...pendingGroup.elements]
            }
        });
    }

    return {
      isAdmin: state.user.permissionLevel >= 2048,
      groups: groups
    }
}, {getAllEvents, getPendingEvents, selectEvent})(CalendarPage);