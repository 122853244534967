import client from './wheelerheirs.axios';
import {showErrorAlert} from "./notification";

export const getAllUsers = () => async (dispatch, getState) => {
  await client(getState().user.accessToken).get('/users')
    .then((users) => {
      dispatch({
        type: "FETCH_USERS",
        payload: users.data
      });

      dispatch({
        type: "FETCH_USERS_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      showErrorAlert(error, "Could not get all users, we are not sure what happened")(dispatch, getState);

      dispatch({
        type: "FETCH_USERS_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};

export const getUserProfile = (user) => async (dispatch, getState) => {
  await client(getState().user.accessToken).get(`/users/${user}`)
    .then((user) => {
      dispatch({
        type: "FETCH_USER_PROFILE",
        payload: user.data
      });

      dispatch({
        type: "FETCH_USER_PROFILE_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      showErrorAlert(error, "Could not get your user profile, we are not sure what happened")(dispatch, getState);

      dispatch({
        type: "FETCH_USER_PROFILE_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};

export const createUser = (user) => async (dispatch, getState) => {
  await client(getState().user.accessToken).post(`/users`, user)
    .then((user) => user.data)
    .then((user) => {
      dispatch({
        type: "CREATE_NEW_USER_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      showErrorAlert(error, "Could not register, the form may contain invalid data.")(dispatch, getState);

      dispatch({
        type: "CREATE_NEW_USER_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};

export const patchUser = (username, user) => async (dispatch, getState) => {
  await client(getState().user.accessToken).patch(`/users/${username}`, user)
    .then((user) => user.data)
    .then((user) => {
      if(username === getState().user.username)
        getUserProfile(username)(dispatch, getState);

      getAllUsers()(dispatch, getState);

      dispatch({
        type: "PATCH_NEW_USER_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      showErrorAlert(error, "Could not update user, the form may contain invalid data.")(dispatch, getState);

      dispatch({
        type: "PATCH_NEW_USER_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};

export const deleteUser = (userId) => async (dispatch, getState) => {
  await client(getState().user.accessToken).delete(`/users/${userId}`)
    .then((nodata) => {
      getAllUsers()(dispatch, getState);
    })
    .catch((error) => {
      showErrorAlert(error, "Could not delete user, we are not sure what happened")(dispatch, getState);
    });
};

export const startResetPasswordProcess = (email) => async (dispatch, getState) => {
  await client(getState().user.accessToken).post(`/users/reset`, {email})
    .then(() => {
      dispatch({
        type: "POST_START_RESET_PASSWORD_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: "POST_START_RESET_PASSWORD_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};

export const resetPasswordSecure = (password, username, token) => async (dispatch, getState) => {
  await client(getState().user.accessToken).post(`/users/${username}/reset/${token}`, {password})
    .then(() => {
      dispatch({
        type: "POST_RESET_PASSWORD_EVENT",
        payload: {
          success: true
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: "POST_RESET_PASSWORD_EVENT",
        payload: {
          success: false,
          error
        },
      });
    });
};