import React from 'react';
import {createMuiTheme, CssBaseline} from "@material-ui/core";

import NotificationDecorator from "./components/notifications/NotificationDecorator";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import NavHelmet from "./components/navigation/NavHelmet";
import {ThemeProvider} from "@material-ui/styles";
import green from "@material-ui/core/colors/green";
import RegistrationForm from "./components/registration/RegistrationForm";
import LoginForm from "./components/login/LoginForm";
import RecoveryForm from "./components/recovery/RecoveryForm";
import PrivateRoute from "./components/security/PrivateRoute";
import HomePage from "./components/homepage/HomePage";
import DocumentPage from "./components/documents/DocumentPage";
import CalendarPage from "./components/calendar/CalendarPage";
import UserManagementPage from "./components/user-management/UserManagementPage";
import ResetPasswordForm from "./components/recovery/ResetPasswordForm";

const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: green,
  }
});

const App = (props) => {
  return (
    <div className="App">
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <NotificationDecorator>
          <BrowserRouter>
            <React.Fragment>
              <NavHelmet {...props}/>
              <Switch>
                <Route exact path="/" component={LoginForm}/>
                {/*<Route exact path="/registration" component={RegistrationForm}/>*/}
                <Route exact path="/recovery" component={RecoveryForm}/>
                <Route exact path="/recovery/:username/:token" component={ResetPasswordForm}/>
                <PrivateRoute exact path="/home" component={HomePage} />
                <PrivateRoute path="/calendar" component={CalendarPage} />
                <PrivateRoute path="/user-management" component={UserManagementPage} />
                <PrivateRoute path="/documents" component={DocumentPage} />
              </Switch>
            </React.Fragment>
          </BrowserRouter>
        </NotificationDecorator>
      </ThemeProvider>
    </div>
  );
}

export default App;
