import React, {useState} from 'react';
import {connect} from 'react-redux';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {clearEvent} from "../../actions/apievents";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import UserProfileForm from "./UserProfileForm";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const UserProfileEditDialog = props => {
  const classes = useStyles();
  const { shouldClose, initialValues } = props;
  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }

  if(shouldClose && isOpen) {
    handleClose();
    props.clearEvent("PATCH_NEW_USER_EVENT");
  }

  return (
    <>
      <Dialog
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={isOpen}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Edit {`${initialValues.firstName} ${initialValues.lastName}`}
            </Typography>
          </Toolbar>
        </AppBar>
        <UserProfileForm username={initialValues.username} initialValues={{...initialValues}}/>
      </Dialog>

      <IconButton
        edge="end"
        aria-label="user profile"
        onClick={() => handleOpen()}
      >
        <AccountCircleIcon/>
      </IconButton>
    </>
  );
};

export default connect(
  (state, ownProps) => ({
    shouldClose: state.apiEvents.PATCH_NEW_USER_EVENT && state.apiEvents.PATCH_NEW_USER_EVENT.success,
    initialValues: state.user
  }),
  {clearEvent}
)(UserProfileEditDialog);