import React from 'react';
import {connect} from 'react-redux';
import ReservationDialog from "./ReservationDialog";
import {clearEvent} from "../../actions/apievents";

const ReservationEditDialog = props => {

  const {event, clearEvent} = props;

  const handleClose = () => {
    clearEvent("SELECT_CALENDAR_EVENT");
  }

  if(!event)
    return null;

  const {id, family, adults, children, rooms, start, end, approved} = event;
  const newRooms = rooms.reduce((acc, cur) => ({
    [cur]: true,
    ...acc
  }), {});

  return (
    <ReservationDialog
      handleClose={handleClose}
      isOpen={!!event}
      initialValues={{id, family, adults, children, ...newRooms, start, end}}
      approved={approved}
    />
  );
};

export default connect((state) => ({
 event: state.apiEvents.SELECT_CALENDAR_EVENT ? state.apiEvents.SELECT_CALENDAR_EVENT.event : null
}), {clearEvent})(ReservationEditDialog);