import React from "react";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {connect} from "react-redux";
import {deleteAlert, hideAlert} from "../../actions/notification";

function NotificationDecorator(props) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    props.hideAlert(0);
  };

  const handleExited = () => {
    props.deleteAlert(0);
  };

  const notification = props.queue.length >= 1 ? props.queue[0] : false;

  return (
    <>
      {props.children}
      {notification && (
        <Snackbar
          open={notification.open}
          autoHideDuration={2500}
          onClose={handleClose}
          anchorOrigin={notification.position}
          onExited={handleExited}
        >
          <Alert onClose={handleClose} severity={notification.severity}>
            {notification.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default connect(
  (state) => ({
    queue: state.notifications,
  }),
  { hideAlert, deleteAlert }
)(NotificationDecorator);
