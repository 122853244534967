import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import HomeCard from "./HomeCard";
import DescriptionIcon from "@material-ui/icons/Description";
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: theme.spacing(6)
  }
}));

const HomePage = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HomeCard
        title="Documents"
        description="Read up on all the latest documents over at the Wheeler Heirs documents page. The Executive Counsel does their best to keep these up to date so you can stay informed."
        buttonText="View Documents"
        buttonLink="/documents"
        icon={<DescriptionIcon style={{fontSize: 64}}/>}
      />
      <HomeCard
        title="Calendar"
        description="Wheeler Heirs now has the whole cottage calendar in a new easy to use format. You can even make a reservation straight from the Website!"
        buttonText="View Calendar"
        buttonLink="/calendar"
        icon={<EventIcon style={{fontSize: 64}}/>}
      />
    </div>
  );
};

export default HomePage;