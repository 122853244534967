import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom'

const PrivateRoute = (props) => {
  const { shouldRender, component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={props => {
        return shouldRender ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: '/',
            state: { from: props.location }
          }} />
        )
      }}
    />
  );
}

export default connect((state) => ({
  shouldRender: state.user.accessToken ? true : false
}), null)(withRouter(PrivateRoute));