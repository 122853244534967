import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from "redux-form";
import {renderTextField} from "../form-wrappers/form-fields";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import {patchUser} from "../../actions/users.service";
import {clearEvent} from "../../actions/apievents";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "350px",
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(4)
  }
}));

const validate = values => {
  const errors = {}

  if (values.password !== values.confirmPassword) {
    errors.password = 'Passwords must match!'
    errors.confirmPassword = 'Passwords must match!'
  }

  return errors
}

const UserProfileForm = props => {
  const classes = useStyles();
  const { handleSubmit, pristine, submitting} = props;

  const onSubmit = values => {
    const {confirmPassword, ...user} = values;

    return props.patchUser(
      props.username,
      user);
  }

  return (
    <form autoComplete="off" className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="firstName"
        component={renderTextField}
        label="First Name"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="off"
        required
      />
      <Field
        name="lastName"
        component={renderTextField}
        label="Last Name"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="off"
        required
      />
      <Field
        name="email"
        component={renderTextField}
        label="E-Mail Address"
        type="email"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="off"
        required
      />
      <Field
        name="password"
        component={renderTextField}
        label="Password"
        type="password"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="off"
      />
      <Field
        name="confirmPassword"
        component={renderTextField}
        label="Confirm Password"
        type="password"
      />
      <Button variant="contained" color="primary" type="submit" disabled={pristine || submitting} margin="normal">
        Save Profile
      </Button>
    </form>
  );
};

export default connect(
  null,
  {patchUser, clearEvent}
)(reduxForm({
  form: 'UserProfileForm',
  validate
})(UserProfileForm));