import React, {useState} from 'react';
import {connect} from 'react-redux';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FolderIcon from "@material-ui/icons/Folder";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import DocumentUpload from "./DocumentUpload";
import DocumentFile from "./DocumentFile";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "600px"
  },
  innerList: {
    width: "100%",
    maxWidth: "550px"
  }
}));

const DocumentFolder = props => {
  const [isOpen, setOpen] = useState(false);

  const classes = useStyles();
  const {folder, folderName, documents} = props;

  return (
    <>
      <ListItem
        className={classes.root}
        key={folderName}
        button
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <ListItemIcon>
          <FolderIcon/>
        </ListItemIcon>
        <ListItemText primary={folderName}/>
        {isOpen ? <ExpandLess/> : <ExpandMore/>}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit className={classes.innerList}>
        <List component="div" disablePadding>
          <DocumentUpload folder={folder}/>
          {documents.map(document => <DocumentFile document={document} folder={folder}/>)}
        </List>
      </Collapse>
    </>
  );
};

export default connect((state, ownProps) => {
  return {
    documents: state.documents[ownProps.folder]
  }
}, null)(DocumentFolder);