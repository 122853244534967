import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center"
  }
}));

const FormPage = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.children}
    </div>
  );
};

export default FormPage;