import React from 'react';
import {Field} from "redux-form";
import {renderCheckbox, renderTextField} from "../form-wrappers/form-fields";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DatePickerField from "../form-wrappers/DatePickerField";

const useStyles = makeStyles((theme) => ({
  twoColumns: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "48% 48%",
    columnGap: theme.spacing(2)
  },
  dontDisplay: {
    display: "none"
  }
}));

const required = value => value ? undefined : 'Required';

const ReservationFormFields = props => {
  const classes = useStyles();

  return (
    <>
      <Field
        name="id"
        component={renderTextField}
        label="id"
        className={classes.dontDisplay}
      />
      <Field
        name="family"
        component={renderTextField}
        label="Reservation Name"
        validate={[ required ]}
      />
      <div className={classes.twoColumns}>
        <Field
          name="start"
          component={DatePickerField}
          label="Arrival Date"
          validate={[ required ]}
        />
        <Field
          name="end"
          component={DatePickerField}
          label="Departure Date"
          validate={[ required ]}
        />
        <Field
          name="Longer Bedroom"
          component={renderCheckbox}
          label="Longer Bedroom"
        />
        <Field
            name="Linger Bedroom"
            component={renderCheckbox}
            label="Linger Bedroom"
        />
        <Field
          name="Sunset"
          component={renderCheckbox}
          label="Sunset Bedroom"
        />
        <Field
          name="Citadel"
          component={renderCheckbox}
          label="Citadel Bedroom"
        />
        <Field
          name="Sunrise"
          component={renderCheckbox}
          label="Sunrise Bedroom"
        />
        <Field
          name="WeeHoos"
          component={renderCheckbox}
          label="Wee-Hoos"
        />
        <Field
          name="Dining Porch Couch"
          component={renderCheckbox}
          label="Dining Porch Couch"
        />
        <Field
          name="Attic"
          component={renderCheckbox}
          label="Attic"
        />
        <Field
          name="adults"
          component={renderTextField}
          label="Adults"
          type="number"
          required
          validate={[ required ]}
        />
        <Field
          name="children"
          component={renderTextField}
          label="Children"
          type="number"
          required
          validate={[ required ]}
        />
      </div>
    </>
  );
};

export default ReservationFormFields;