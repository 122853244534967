import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from "redux-form";
import {useParams, Redirect} from 'react-router-dom';
import {renderTextField} from "../form-wrappers/form-fields";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import logo from '../../images/wh.svg';
import CardMedia from "@material-ui/core/CardMedia";
import FormPage from "../form-wrappers/FormPage";
import {resetPasswordSecure} from "../../actions/users.service";
import {clearEvent} from "../../actions/apievents";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "350px",
    padding: theme.spacing(2)
  },
  form: {
    display: "flex",
    flexDirection: "column"
  },
  img: {
    objectFit: "contain"
  },
  message: {
    marginTop: theme.spacing(2),
    fontSize: 16
  }
}));

const validate = values => {
  const errors = {}

  if (values.password !== values.confirmPassword) {
    errors.password = 'Passwords must match!'
    errors.confirmPassword = 'Passwords must match!'
  }

  return errors
}

const ResetPasswordForm = props => {
  const classes = useStyles();
  const { shouldRedirect, handleSubmit, pristine, submitting } = props;
  let { username, token } = useParams();

  const onSubmit = values => props.resetPasswordSecure(values.password, username, token);

  if(shouldRedirect) {
    props.clearEvent("POST_RESET_PASSWORD_EVENT");

    return <Redirect to={{
      pathname: '/',
      state: { from: props.location }
    }} />
  }

  return (
    <FormPage>
      <Card className={classes.card} raised={true}>
        <CardMedia component="img" height="140" image={logo} classes={{img: classes.img}}/>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Field
            name="password"
            component={renderTextField}
            label="Password"
            type="password"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="off"
          />
          <Field
            name="confirmPassword"
            component={renderTextField}
            label="Confirm Password"
            type="password"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="off"
          />
          <Button variant="contained" color="primary" type="submit" disabled={pristine || submitting} margin="normal">
            Reset Password
          </Button>
        </form>
      </Card>
    </FormPage>
  );
};

export default connect((state) => ({
  shouldRedirect: state.apiEvents.POST_RESET_PASSWORD_EVENT && state.apiEvents.POST_RESET_PASSWORD_EVENT.success
}), {resetPasswordSecure, clearEvent})(reduxForm({
  form: 'ResetPasswordForm',
  validate
})(ResetPasswordForm));