import React from "react";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export const renderSwitch = ({input, label, meta: {touched, error}, ...custom}) => (
  <span>
    {label}
    <Switch
      label={label}
      checked={!!input.value}
      onChange={input.onChange}
      color="primary"
      margin="normal"
    />
  </span>
);

export const renderTextField = ({input, label, meta: {touched, error}, ...custom}) => (
  <TextField
    label={label}
    {...input}
    {...custom}
    margin="normal"
    error={!!error}
  />
);

export const renderCheckbox = ({ input, label }) => (
  <FormControlLabel
    control={
      <Checkbox
        label={label}
        checked={!!input.value}
        onChange={input.onChange}
        color="primary"
        margin="normal"
      />
    }
    label={label}
  />
);