import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

const localStorageReducer = (key, reducer) => {
  return persistReducer({ key, storage }, reducer);
};

const basicReducer = (defaultState, actionName) => (state = defaultState, action) => {
  switch (action.type) {
    case actionName:
      return action.payload;
    default:
      return state;
  }
};

const userProfileReducer = (state = {}, action) => {

  switch (action.type) {
    case "FETCH_USER_INFO":
    case "FETCH_USER_PROFILE":
      if(action.payload.logout) {
        return {};
      }

      return {...state, ...action.payload};
    default:
      return state;
  }
}

const apiEventsReducer = (state = {}, action) => {
  if(!action.type.includes("_EVENT"))
    return state;

  if(action.payload === "DELETE") {
    const {[action.type]: value, ...removedAction} = state;
    return removedAction;
  }

  return {...state, [action.type]: action.payload};
};

export default combineReducers({
  form: formReducer,
  notifications: basicReducer([], "NOTIFY"),
  apiEvents: apiEventsReducer,
  user: localStorageReducer("user", userProfileReducer),
  users: basicReducer([], 'FETCH_USERS'),
  documents: basicReducer([], 'FETCH_DOCUMENTS'),
  calendar: basicReducer([], "FETCH_CALENDAR"),
  pendingCalendar: basicReducer([], "FETCH_CALENDAR_PENDING")
});