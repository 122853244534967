import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from "redux-form";
import {renderTextField} from "../form-wrappers/form-fields";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import logo from '../../images/wh.svg';
import CardMedia from "@material-ui/core/CardMedia";
import FormPage from "../form-wrappers/FormPage";
import {login} from "../../actions/auth.service";
import {clearEvent} from "../../actions/apievents";
import {Redirect} from "react-router-dom";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "350px",
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  form: {
    display: "flex",
    flexDirection: "column"
  },
  img: {
    objectFit: "contain"
  },
  splitLinks: {
    display: "flex",
    justifyContent: "space-around",
  }
}));

const LoginForm = props => {
  const classes = useStyles();
  const { handleSubmit, pristine, submitting, shouldRedirect } = props;

  const onSubmit = values => {
    props.login(values.username, values.password);
  };

  if(shouldRedirect) {
    props.clearEvent("FETCH_USER_INFO_EVENT");

    return <Redirect to={{
      pathname: '/home',
      state: { from: props.location }
    }} />
  }

  return (
    <FormPage>
      <Card className={classes.card} raised={true}>
        <CardMedia component="img" height="140" image={logo} classes={{img: classes.img}}/>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Field
            name="username"
            component={renderTextField}
            label="Username"
          />

          <Field
            name="password"
            component={renderTextField}
            label="Password"
            type="password"
          />
          <Button variant="contained" color="primary" type="submit" disabled={pristine || submitting} margin="normal">
            Login
          </Button>
        </form>
      </Card>
      <Card className={classes.card} raised={true}>
        <div className={classes.splitLinks}>
          <Link href="/recovery">
            Forgot Password?
          </Link>
          {/*<Link href="/registration">*/}
          {/*  I need an account!*/}
          {/*</Link>*/}
        </div>
      </Card>
    </FormPage>
  );
};

export default connect((state) => ({
    shouldRedirect: state.apiEvents.FETCH_USER_INFO_EVENT && state.apiEvents.FETCH_USER_INFO_EVENT.success
}), {login, clearEvent})(reduxForm({
  form: 'LoginForm'
})(LoginForm));