import axios from "axios";

const requestConfigInterceptor = (jwt) => (config) => {
  if(jwt !== null)
    config.headers.authorization = `Bearer ${jwt}`;

  return config;
};

export default (token = null, config = {}) => {
  const client = axios.create({
    baseURL: `/v1/wheelerheirs`,
    validateStatus: function (status) {
      return status < 400; // Resolve only if the status code is less than 500
    },
    ...config
  });

  client.interceptors.request.use(
    requestConfigInterceptor(token)
  );
  return client;
};
