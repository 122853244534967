import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import UserManagementEditDialog from "./UserManagementEditDialog";

const UserTableRow = props => {
  const {user} = props;

  return (
    <TableRow key={user.id}>
      <TableCell component="th" scope="row">
        {user.firstName} {user.lastName}
      </TableCell>
      <TableCell>{user.username}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.permissionLevel}</TableCell>
      <TableCell>
        <UserManagementEditDialog username={user.username}/>
      </TableCell>
    </TableRow>
  );
};

export default UserTableRow;