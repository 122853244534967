import React from 'react';
import {connect} from 'react-redux';
import ListItem from "@material-ui/core/ListItem";
import FileUploadMini from "../dropzone/FileUploadMini";
import {uploadDocument} from "../../actions/document.service";

const DocumentUpload = props => {
  const {isAdmin, folder, uploadDocument} = props;

  if(!isAdmin)
    return null;

  return (
    <ListItem
      key={"upload"}
      button
    >
      <FileUploadMini
        uploadCallback={(file) => uploadDocument(folder, file)}
        acceptedTypes={"application/pdf"}
      />
    </ListItem>
  );
};

export default connect((state) => ({
  isAdmin: state.user && state.user.permissionLevel
}), {uploadDocument})(DocumentUpload);