import React from 'react';
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import DescriptionIcon from '@material-ui/icons/Description';
import logo from "../../images/wh.svg";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "350px",
    padding: theme.spacing(2),
    margin: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));

const HomeCard = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const {title, description, buttonText, buttonLink, icon} = props;

  return (
    <Card className={classes.card} raised={true}>
      {icon}
      <Typography variant="h5" component="h2">
        {title}
      </Typography>
      <Typography>
        {description}
      </Typography>
      <Button variant="contained" color="primary" className={classes.button} onClick={() => history.push(buttonLink)}>
        {buttonText}
      </Button>
    </Card>
  );
};

export default HomeCard;