import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {applyMiddleware, createStore} from "redux";
import { persistStore } from 'redux-persist';
import reducers from "./reducers";
import thunk from "redux-thunk";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";

const store = createStore(reducers, applyMiddleware(thunk));
const persist = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persist}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

